const queryGetBasicMeta = require('@/graphql/queries/v2/listeLecture/queryGetBasicMeta.gql')
const queryGetContent = require('@/graphql/queries/v2/listeLecture/queryGetContent.gql')
const queryGetUsagers = require('@/graphql/queries/v2/listeLecture/queryGetUsagers.gql')

export default {
  data () {
    return {
      lectureBasicMeta: null,
      lectureContents: [],
      lectureGroups: [],
      lectureLoadingContents: false,
      lectureLoadingGroups: false
    }
  },
  methods: {
    async loadAllInfoForLectureId (lectureId, loadContent = true, loadUsagers = true) {
      // Using a Lecture Id (aka calendrierCalendrierElement Identifiant)
      // Will fetch the Basic Meta, them use this basic meta to get all the usagers and content
      this.lectureLoadingContents = true
      this.lectureLoadingGroups = true
      this.lectureBasicMeta = await this.getBasicMeta(lectureId)
      if (loadContent) {
        this.loadContentFromBasicMeta()
      }
      if (loadUsagers) {
        this.loadAllUsagersForClassesFromBasicMeta()
      }
    },
    async loadContentFromBasicMeta () {
      // Since the content is the same for every element, we are only searching for the content of the first element
      const batchesList = this.lectureBasicMeta?.resultat?.length ? this.lectureBasicMeta.resultat[0].batch : []
      const allContent = await this.getLectureContentFromBatch(batchesList)
      this.lectureContents = allContent
      this.lectureLoadingContents = false
    },
    async loadAllUsagersForClassesFromBasicMeta () {
      const calEle = this.lectureBasicMeta?.resultat

      await Promise.allSettled(calEle.map(group => this.fillLectureGroupFromGroup(group)))
      this.lectureLoadingGroups = false
    },
    async getBasicMeta (lectureId) {
      const { data } = await this.$apollo.query({
        query: queryGetBasicMeta,
        variables: { identifiant: lectureId },
        fetchPolicy: 'no-cache'
      })
      return data.calendrierCalendrierElements
    },
    async getLectureContentFromBatch (batch) {
      const { data } = await this.$apollo.query({
        query: queryGetContent,
        variables: { batch },
        fetchPolicy: 'no-cache'
      })
      return data.calendrierCalendrierElements?.resultat
    },
    async fillLectureGroupFromGroup (group, page = 1) {
      // Recursive method to fill the lectureGroups object
      const { data } = await this.$apollo.query({
        query: queryGetUsagers,
        variables: { id: group.id, page },
        fetchPolicy: 'no-cache'
      })
      const groupSearched = this.lectureGroups.find(lg => lg.proprietaireId === group.calendrier.id)
      const alreadyFetchedUsagers = groupSearched?.usagers ?? []
      const newFetchUsagers = data.calendrierCalendrierElement?.usagers?.resultat ?? []
      const globalFetchUsagers = [...alreadyFetchedUsagers, ...newFetchUsagers]
      if (data.calendrierCalendrierElement?.usagers?.total > 0 && !groupSearched) {
        this.lectureGroups.push({
          calendrier: group.calendrier,
          proprietaireId: group.calendrier?.id,
          titre: group.calendrier?.proprietaire?.titre,
          usagers: []
        })
      }
      const grouptoFetchUsagers = this.lectureGroups.find(lg => lg.proprietaireId === group.calendrier.id)
      grouptoFetchUsagers.usagers = globalFetchUsagers
      // Fetch the next page if we havent fetched all the usagers yet
      if (data.calendrierCalendrierElement?.usagers?.total > globalFetchUsagers.length) {
        await this.fillLectureGroupFromGroup(group, page + 1)
      }
      return true
    }
  }
}
