<script>
import Status from '@/mixins/status.js'
import ChatControls from '@/mixins/chatControls.js'
import RetrieveIdentification from '@/mixins/retrieveIdentification.js'
import ConnectEleves from '@/mixins/connectEleve.js'
import TableauResize from '@/mixins/tableauResize.js'

import { maxBy } from 'lodash-es'
export default {
  name: 'tableauElevesResults',
  mixins: [ChatControls, RetrieveIdentification, ConnectEleves, Status, TableauResize],
  components: {
  },
  props: {
    // users: { required: true, type: Array },
    identifiant: { required: false, type: Boolean, default: false },
    batch: { required: false, type: Boolean, default: false },
    search: { required: false, type: String, default: null },
    extraContent: { required: false, type: Boolean, default: false },
    calendrier: { required: false, type: Object, default: () => {} },
    showNote: { required: false, type: Boolean, default: true },
    maxShow: { required: false, type: Number, default: 10 },
    rounded: { required: false, type: Boolean, default: false },
    usagers: { required: false, type: Array, default: () => {} }
  },
  data () {
    return {
      elevesDevoirs: null,
      totalEleve: null,
      elementUsagerIndex: null,
      eleves: null,
      expanded: [],
      singleExpand: true,
      page: 1
    }
  },
  computed: {
    headersResults () {
      if (this.showNote) {
        return [
          {
            text: this.$t('eleves.eleve'),
            align: 'left',
            sortable: true,
            value: 'name',
            show: true
          },
          { text: this.$t('messages.etat'), value: 'status', show: true, sortable: false },
          { text: this.$t('partage.resultat'), value: 'result', align: 'left', show: true, sortable: false },
          // { text: 'Group', value: 'group', sortable: true, show: true },
          { text: this.$t('partage.actions'), value: 'actions', align: 'right', sortable: false, show: true },
          { text: '', value: 'data-table-expand', show: this.extraContent, sortable: false }
        ]
      } else {
        return [
          {
            text: 'Élève',
            align: 'left',
            sortable: true,
            value: 'name',
            show: true
          },
          { text: 'Actions', value: 'actions', align: 'right', sortable: false, show: true },
          { text: '', value: 'data-table-expand', show: this.extraContent }
        ]
      }
    },
    _headersResults () {
      // Certaines colonnes sont conditionnelles -> J'utilise cette computed pour les afficher / cacher
      return this.headersResults.filter(x => x.show)
    },
    userInfos () {
      return this.$store.state.User.profile
    },
    sortResults () {
      let tableau = []
      if (this.eleves) {
        this.eleves.forEach((rel, index) => {
          let entry = {}
          entry.id = rel.usager.id
          entry.avatar = rel.usager.avatar ? rel.usager.avatar.imageUrl : null
          entry.nom = rel.usager.info.nom ? rel.usager.info.nom : null
          entry.name = rel.usager.info.nom ? rel.usager.info.prenom + ' ' + rel.usager.info.nom : this.$t('partage.n-d')
          entry.username = rel.usager.info.username ? rel.usager.info.username : this.$t('partage.n-d')
          entry.result = rel.note ? Math.round(rel.note) : this.$t('partage.n-d')
          entry.status = {
            color: this.parseStatus(rel.statut, rel.note).color,
            title: this.parseStatus(rel.statut, rel.note).name
          }
          tableau.push(entry)
        })
      }
      return tableau
    },
    elevesItems () {
      return this.usagers?.length ? this.usagers.map(u => ({
        id: u.id,
        avatar: null,
        nom: u?.info.nom,
        name: `${u.info.prenom} ${u.info.nom}`,
        username: u.info.username
      })) : this.sortResults
    }
  },
  apollo: {
    elevesDevoirs: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElementsUsagers.gql'),
      variables () {
        const filtre = {}
        if (this.batch) {
          filtre.batches = [this.$route.params.id ? this.$route.params.id : this.$route.query.document]
        }
        if (this.identifiant) {
          filtre.identifiants = [this.$route.params.id ? this.$route.params.id : this.$route.query.document]
        }
        filtre.calendriers = [this.calendrier.id]
        return {
          filtre
        }
      },
      update (data) {
        if (this.page === 1) {
          if (data.calendrierCalendrierElements.resultat) {
            this._eleves = {}

            data.calendrierCalendrierElements.resultat.forEach((calendierElement, index) => {
              // Normalement, elementsUsagers.total est commun à tous les calendierElement
              if (index === 0) {
                this.totalElementUsager = calendierElement.elementsUsagers.total
                this.elementUsagerIndex = calendierElement.elementsUsagers.resultat.length
              }

              calendierElement.elementsUsagers.resultat.forEach((usager) => {
                if (this._eleves[usager.id]) {
                  this._eleves[usager.id].note += usager.note
                } else {
                  this._eleves[usager.id] = usager
                  this._eleves[usager.id].note = 0
                }
              })
            })
          }
          this.$nextTick(() => {
            this.fetchMoreUsagers()
          })
        }
        return data.calendrierCalendrierElements
      },
      skip () {
        return this.usagers?.length
      },
      fetchPolicy: 'network-only'
    }
  },
  watch: {
    '$store.state.App.snackbarGlobalContent': {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.description && newVal.description.includes(this.$t('alerte.tableau-partage'))) {
          this.$apollo.queries.elevesDevoirs.refetch()
        }
      }
    }
  },
  methods: {
    async getGroupeOfEleve (item) {
      await this.$apollo.query({
        query: require('@/graphql/queries/v2/searchSalleClasses.gql'),
        variables: {
          filtre: {
            usagers: [item.id]
          }
        }
      }).then(({ data }) => {
        this.retrieveSingleIdentification(data.searchSalleClasses.resultat)
      })
    },
    async fetchMoreUsagers () {
      if (this.elementUsagerIndex < this.totalElementUsager) {
        this.page++
        await this.$apollo.queries.elevesDevoirs.fetchMore({
          variables: {
            page: this.page
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            fetchMoreResult.calendrierCalendrierElements.resultat.forEach((calendierElement, index) => {
              if (index === 0) {
                this.elementUsagerIndex += calendierElement.elementsUsagers.resultat.length
              }
              calendierElement.elementsUsagers.resultat.forEach((usager) => {
                if (this._eleves[usager.id]) {
                  this._eleves[usager.id].note += usager.note
                } else {
                  this._eleves[usager.id] = usager
                  this._eleves[usager.id].note = 0
                }
              })
            })
          }
        })
        this.fetchMoreUsagers()
      } else {
        this.eleves = Object.values(this._eleves)

        let temp = null
        if (this.eleves.length > 0) {
          temp = maxBy(this.eleves, (eleve) => { return eleve.note })
          if (temp) {
            this.$emit('maxNote', temp.note.toFixed(2))
          } else {
            this.$emit('maxNote', null)
          }
        }
      }
    },
    customUserFilter (value, search, item) {
      return value != null && search != null && typeof value === 'string' && (value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.username.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1)
    },
    generateAndEmitMaxNote () {
      if (!this.usagers?.length) {
        this.$emit('maxNote', null)
        return
      }
      let maxNote = null
      this.usagers.forEach(u => {
        if (u.note !== null && u.note !== undefined) {
          if (maxNote === null) {
            maxNote = u.note
          } else {
            maxNote = Math.max(maxNote, u.note)
          }
        }
      })
      this.$emit('maxNote', maxNote)
    }
  },
  mounted () {
    this.generateAndEmitMaxNote()
  }
}
</script>

<template lang='pug'>
.tableau-eleves-results(id='tableau')
  v-data-table.elevation-1(
    :options='{itemsPerPage: maxShow}'
    :headers='_headersResults'
    :items='elevesItems'
    :single-expand='singleExpand'
    :show-expand='extraContent'
    :expanded.sync="expanded"
    item-key='id'
    :search='search'
    :custom-filter="customUserFilter"
    :no-data-text='$t("eleves.aucun-eleve")'
    :no-results-text='$t("alerte.aucun-result-recherche", { search })'
    :mobile-breakpoint='0'
    light
    :class='{"light" : $store.state.Preferences.darkMode, "white" : !$store.state.Preferences.darkMode}'
    :style='{borderRadius: rounded ? "10px" : "0px"}'
    :hide-default-footer='elevesItems && elevesItems.length <= maxShow'
  )
    template(v-slot:item.name='{ item }')
      div.flex.items-center
        div(style='line-height:1.15')
          div(style='word-break:break-all')
            template(v-if='item.name !== $t("partage.n-d")')
              span.b {{ item.name.split(' ')[0] }} &nbsp;
              span {{item.name.split(' ')[1]}}
            templatE(v-else)
              span {{item.name}}
          div.o-50(style='font-size:10px;') @{{item.username}}
    template(v-slot:item.actions='{ item }')
      div.flex.items-center.justify-end
        v-menu(offset-y)
          template(v-slot:activator='{ on }')
            v-btn.mr1(icon,small, @click.prevent.stop='', v-on='on')
              font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
          v-card.pv2
            v-list(dense, nav)
              //- v-list-item(@click='')
                font-awesome-icon.f6.mr2(:icon="['fad', 'shapes']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Détails du compte
              v-list-item(@click='goChat')
                font-awesome-icon.f6.mr2(:icon="['fad', 'comment-smile']")
                v-list-item-content
                  v-list-item-title {{ $t('action.clavarder') }}
              v-divider.mv2
              v-list-group(@click.stop='', color='secondary')
                template(v-slot:activator)
                  v-list-item-content
                    v-list-item-title {{ $t('action.autres-actions') }}
                v-list-item(:to='{ name: "enfantDetail", params: { id: item.id } }')
                  v-list-item-content
                    v-list-item-title {{ $t('action.go-eleve') }}
                v-list-item(@click='getGroupeOfEleve(item)')
                  v-list-item-content
                    v-list-item-title {{ $t('action.obtenir-lidentifiant') }}
        v-btn(icon, small, :to='{ name: "enfantDetail", params: { id: item.id } }')
          font-awesome-icon.f5(:icon="['far', 'arrow-right']")
    template(v-slot:item.status='{ item }')
      div.flex.items-center(v-if='tableauWidth && tableauWidth > 400 && item.status')
        v-avatar.mr2(:color='item.status.color', size='6')
        span.secondary--text.text--lighten-3.f7 {{ item.status.title }}
      v-avatar(v-else-if="item.status", :color='item.status.color', size='10', v-tooltip.bottom="{ content: item.status.title, offset:'10px'}")
    template(v-slot:expanded-item='{ headers, item }')
      td(:colspan="headers.length")
        div
          span {{ $t('messages.info-suppl') }}
          span.b {{item.name}}
          span {{ $t('messages.a-venir') }}

</template>
<style lang='sass'>
  @import 'src/styles/pages/_groupeDetail'
  .tableau-eleves-results
    .v-data-table
      tbody
        tr
          td
            font-size: 12px !important
</style>
