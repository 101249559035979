export default {
  computed: {
    showRetrieveIdentifications: {
      set (val) { this.$store.commit('Dialogs/setRetrieveIdentifications', val) },
      get () { return this.$store.state.Dialogs.showRetrieveIdentifications }
    },
    selectedEleves: {
      set (val) { this.$store.commit('Dialogs/setSelectedEleves', val) },
      get () { return this.$store.state.Dialogs.selectedEleves }
    }
  },
  methods: {
    retrieveClassIdentification (item) {
      this.selectedEleves = item
      this.showRetrieveIdentifications = true
    },
    async retrieveSingleIdentification (id, user) {
      if (user) {
        await this.$apollo.query({
          query: require('@/graphql/queries/v2/searchSalleClasses.gql'),
          variables: {
            filtre: {
              usagers: [user.id]
            }
          }
        }).then(({ data }) => {
          this.selectedEleves = data.searchSalleClasses.resultat
        })
      } else {
        this.selectedEleves = []
        if (Array.isArray(id)) {
          this.selectedEleves = [...id]
        } else {
          this.selectedEleves.push(id)
        }
      }
      this.showRetrieveIdentifications = true
    },
    // Manque une fonction pour faire le pont entre la selection d'eleves dans le composant et la fonction de recuperation
    retrieveMultipleIdentification (ids) {
      this.selectedEleves = []
      this.selectedEleves = ids
      this.showRetrieveIdentifications = true
    }
  }
}
