var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableau-eleves-results",attrs:{"id":"tableau"}},[_c('v-data-table',{staticClass:"elevation-1",class:{"light" : _vm.$store.state.Preferences.darkMode, "white" : !_vm.$store.state.Preferences.darkMode},style:({borderRadius: _vm.rounded ? "10px" : "0px"}),attrs:{"options":{itemsPerPage: _vm.maxShow},"headers":_vm._headersResults,"items":_vm.elevesItems,"single-expand":_vm.singleExpand,"show-expand":_vm.extraContent,"expanded":_vm.expanded,"item-key":"id","search":_vm.search,"custom-filter":_vm.customUserFilter,"no-data-text":_vm.$t("eleves.aucun-eleve"),"no-results-text":_vm.$t("alerte.aucun-result-recherche", { search: _vm.search }),"mobile-breakpoint":0,"light":"","hide-default-footer":_vm.elevesItems && _vm.elevesItems.length <= _vm.maxShow},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticStyle:{"line-height":"1.15"}},[_c('div',{staticStyle:{"word-break":"break-all"}},[(item.name !== _vm.$t("partage.n-d"))?[_c('span',{staticClass:"b"},[_vm._v(_vm._s(item.name.split(' ')[0])+" ")]),_c('span',[_vm._v(_vm._s(item.name.split(' ')[1]))])]:_c('templatE',[_c('span',[_vm._v(_vm._s(item.name))])])],2),_c('div',{staticClass:"o-50",staticStyle:{"font-size":"10px"}},[_vm._v("@"+_vm._s(item.username))])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center justify-end"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr1",attrs:{"icon":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},on),[_c('font-awesome-icon',{staticClass:"f6",attrs:{"icon":['fas', 'ellipsis-v']}})],1)]}}],null,true)},[_c('v-card',{staticClass:"pv2"},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":_vm.goChat}},[_c('font-awesome-icon',{staticClass:"f6 mr2",attrs:{"icon":['fad', 'comment-smile']}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('action.clavarder')))])],1)],1),_c('v-divider',{staticClass:"mv2"}),_c('v-list-group',{attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('action.autres-actions')))])],1)]},proxy:true}],null,true)},[_c('v-list-item',{attrs:{"to":{ name: "enfantDetail", params: { id: item.id } }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('action.go-eleve')))])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.getGroupeOfEleve(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('action.obtenir-lidentifiant')))])],1)],1)],1)],1)],1)],1),_c('v-btn',{attrs:{"icon":"","small":"","to":{ name: "enfantDetail", params: { id: item.id } }}},[_c('font-awesome-icon',{staticClass:"f5",attrs:{"icon":['far', 'arrow-right']}})],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.tableauWidth && _vm.tableauWidth > 400 && item.status)?_c('div',{staticClass:"flex items-center"},[_c('v-avatar',{staticClass:"mr2",attrs:{"color":item.status.color,"size":"6"}}),_c('span',{staticClass:"secondary--text text--lighten-3 f7"},[_vm._v(_vm._s(item.status.title))])],1):(item.status)?_c('v-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({ content: item.status.title, offset:'10px'}),expression:"{ content: item.status.title, offset:'10px'}",modifiers:{"bottom":true}}],attrs:{"color":item.status.color,"size":"10"}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('messages.info-suppl')))]),_c('span',{staticClass:"b"},[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(_vm.$t('messages.a-venir')))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }